body {
	margin:      0;
	padding:     0;
	font-family: 'Poppins', sans-serif;
	font-weight: 400;
	font-size:   14px;
	line-height: 1.6;
	color:       #606060;
}

a, a:visited, a:hover, a:focus {
	cursor: pointer;
	color:  #009efb;
}

.page-wrapper {
	padding-left:  15px;
	padding-right: 15px;
	padding-top:   70px;
	margin-top:    -70px;
	min-height:    100vh;
}

.no-footer.page-wrapper {
	padding-bottom: 0;
}

.scroll-sidebar {
	max-width: none;
}

.sidebar-nav {
	text-align: center;
}

.left-sidebar + .page-wrapper {
	padding-top: 122px;
	margin-top:  -122px;
}

@media (max-width: 768px) {
	.mini-sidebar .left-sidebar + .page-wrapper {
		padding-top: 70px;
		margin-top:  -52px;
	}
}

::placeholder {
	color:   #b3b3b3;
	opacity: 1;
}

.modal-content {
	-webkit-box-shadow: none;
	box-shadow:         none;
}

.modal-body {
	max-height: calc(
						100vh
						- 81px/*modal-header*/
						- 86px/*modal-footer*/
						- 30px/*top margin*/
						- 30px/*bottom margin*/
						- 2px/*borders*/
						- 90px /*tablet browser url bar*/
				);
	overflow-y: auto;
}

.modal-title {
	flex-grow: 1;
}

.modal-header .close {
	order:      1;
	float:      none;
	margin-top: -1rem;
	color:      #000;
	opacity:    .5;
	font-size:  2.8em;
}

.modal-header .close:focus, .modal-header .close:hover {
	text-decoration: none;
	opacity:         .75;
}

.modal-xl .modal-dialog {
	width:     calc(100vw - 30px);
	max-width: none;
}

@media (min-width: 1900px) {
	.modal-xl .modal-dialog {
		width:     1850px;
		max-width: none;
	}
}

.days-left.overdue, .overdue .days-left {
	color: #ff3636;
}

.authenticate {
	background:              url('./images/login-background.jpg') no-repeat center center fixed;
	-webkit-background-size: cover;
	-moz-background-size:    cover;
	-o-background-size:      cover;
	background-size:         cover;
}

.simple-table .d-table-cell {
	padding: 0 3px;
}

.simple-table .d-table-cell:first-child {
	padding-left: 0;
}

.simple-table .d-table-cell:last-child {
	padding-right: 0;
}

.react-viewer {
	z-index:  100000;
	position: relative;
}

@media (min-width: 768px) {
	.mini-sidebar .footer {
		left: 0
	}
}