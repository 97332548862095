@import '../../themeCSS/variables.css';

.file-section .file-list {
	list-style-type: none;
}

.file-section .file-list li > * {
	vertical-align: middle;
}

.file-section .file-list button {
	margin-left: 1em;
}

.file-section .file-list .card-header {
	cursor:    pointer;
	font-size: 16px;
}

.file-section .file-list .card-header > * {
	vertical-align: middle;
}

.file-section .file-list .card-actions a {
	color:        #fff;
	opacity:      1;
	padding-left: 12px;
}

.file-section .pdf-viewer {
	width:      100%;
	height:     70vh;
	min-height: 30em;
}

.analysis-modal .modal-title {
	display: flex;
}

.analysis-modal .modal-footer {
	justify-content: flex-start;
}

.analysis-modal .modal-footer button:first-of-type, .analysis-modal .modal-footer .delete-button + button, .analysis-modal .modal-footer .navigate-button + button {
	margin-left: auto;
}

.analysis-modal .modal-footer button.delete-button {
	margin-left: 0;
}

.analysis-modal {
	font-size: 18px;
}

.analysis-modal .note {
	height: 5em;
}

.analysis-modal .file-icon {
	font-size:      1.3em;
	vertical-align: text-top;
	margin-right:   .2em;
}

.analysis-modal .delete-button {
	margin-right: 25px;
}

.analysis-modal .card-title {
	font-weight:   bold;
	font-size:     0.8em;
	margin-bottom: .5rem;
	color: var(--fj-dark-text-color);
}

.analysis-modal .log {
	max-height: 200px;
	overflow:   auto;
}

.analysis-modal .log .timestamp {
	font-size: smaller;
	color:     var(--fj-light-text-color);
	display:   block;
}

.analysis-modal .log li {
	margin-bottom: 10px;
}

.mark-button .name {
	display:   block;
	font-size: 12px;
}

.analysis-modal .section-body {
	overflow:   auto;
	max-height: 300px;
}

.analysis-key-input {
	margin-left: 15px;
}

.analysis-modal .status span {
	display:      inline-block;
	margin-right: 10px;
}

.analysis-modal .section {
	padding-bottom: 1rem;
	border-bottom:  1px solid rgba(0, 0, 0, .1);
	margin-bottom:  1rem;
	min-width:      300px;
}

.analysis-modal .status-section {
	display:        flex;
	flex-direction: row;
	align-items:    baseline;
}

.analysis-modal .status-section .card-title {
	margin-right: 10px;
}

.analysis-modal .date-section table td {
	padding: .5rem;
}

.analysis-modal .form-field .name {
	font-size: 15px;
	color:     var(--fj-light-text-color);
	display:   block;
}

.analysis-modal .form-field .value {
	font-size: 20px;
	display:   block;
}

@media (max-width: 768px) {
	.analysis-modal {
		font-size: 16px;
	}

	.analysis-modal .btn {
		font-size: 12px;
	}

	.analysis-modal .delete-button {
		margin-right: 5px;
	}

	.analysis-key-input {
		display: none;
	}
}