@import '../../themeCSS/variables.css';

.firebaseui-textfield.mdl-textfield .firebaseui-label::after {
	background-color: var(--fj-green-color);
}

.firebaseui-form-actions .mdl-button--raised.mdl-button--colored.firebaseui-button {
	background:         var(--fj-green-color);
	border:             1px solid var(--fj-green-color);
	-webkit-box-shadow: 0 2px 2px 0 rgba(126, 181, 73, 0.14), 0 3px 1px -2px rgba(126, 181, 73, 0.2), 0 1px 5px 0 rgba(126, 181, 73, 0.12);
	box-shadow:         0 2px 2px 0 rgba(126, 181, 73, 0.14), 0 3px 1px -2px rgba(126, 181, 73, 0.2), 0 1px 5px 0 rgba(126, 181, 73, 0.12);
	-webkit-transition: 0.2s ease-in;
	-o-transition:      0.2s ease-in;
	transition:         0.2s ease-in;
}

.firebaseui-form-actions .mdl-button--raised.mdl-button--colored.firebaseui-button:hover {
	background:         var(--fj-green-hover-color);
	-webkit-box-shadow: 0 14px 26px -12px rgba(126, 181, 73, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(126, 181, 73, 0.2);
	box-shadow:         0 14px 26px -12px rgba(126, 181, 73, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(126, 181, 73, 0.2);
	border:             1px solid var(--fj-green-hover-color);
}

.firebaseui-form-actions .mdl-button--raised.mdl-button--colored.firebaseui-button:focus {
	background:         var(--fj-green-color);
	-webkit-box-shadow: 0 14px 26px -12px rgba(126, 181, 73, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(126, 181, 73, 0.2);
	box-shadow:         0 14px 26px -12px rgba(126, 181, 73, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(126, 181, 73, 0.2);
}

.mdl-button--primary.mdl-button--primary {
	color: #fff;

	background:         var(--fj-green-color);
	border:             1px solid var(--fj-green-color);
	-webkit-box-shadow: 0 2px 2px 0 rgba(126, 181, 73, 0.14), 0 3px 1px -2px rgba(126, 181, 73, 0.2), 0 1px 5px 0 rgba(126, 181, 73, 0.12);
	box-shadow:         0 2px 2px 0 rgba(126, 181, 73, 0.14), 0 3px 1px -2px rgba(126, 181, 73, 0.2), 0 1px 5px 0 rgba(126, 181, 73, 0.12);
	-webkit-transition: 0.2s ease-in;
	-o-transition:      0.2s ease-in;
	transition:         0.2s ease-in;
}

.mdl-button--primary.mdl-button--primary:hover {
	background:         var(--fj-green-hover-color);
	border:             1px solid var(--fj-green-hover-color)		;
	-webkit-box-shadow: 0 14px 26px -12px rgba(126, 181, 73, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(126, 181, 73, 0.2);
	box-shadow:         0 14px 26px -12px rgba(126, 181, 73, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(126, 181, 73, 0.2);
}

.mdl-button--primary.mdl-button--primary:focus {
	background:         var(--fj-green-color);
	-webkit-box-shadow: 0 14px 26px -12px rgba(126, 181, 73, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(126, 181, 73, 0.2);
	box-shadow:         0 14px 26px -12px rgba(126, 181, 73, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(23, 105, 255, 0.2);
}

.firebaseui-link {
	color: var(--fj-green-color)
}

.firebaseui-link:hover, .firebaseui-link:visited, .firebaseui-link:focus {
	color: var(--fj-green-hover-color);
}

.mdl-progress > .progressbar {
	background-color: var(--fj-green-color);
}

.mdl-progress > .bufferbar {
	background-image: linear-gradient(to right, rgba(255, 255, 255, .7), rgba(255, 255, 255, .7)), linear-gradient(to right, var(--fj-green-color), var(--fj-green-color));
}

@supports (-webkit-appearance:none) {
	.mdl-progress:not(.mdl-progress--indeterminate):not(.mdl-progress--indeterminate) > .auxbar, .mdl-progress:not(.mdl-progress__indeterminate):not(.mdl-progress__indeterminate) > .auxbar {
		background-image: linear-gradient(to right, rgba(255, 255, 255, .7), rgba(255, 255, 255, .7)), linear-gradient(to right, var(--fj-green-color), var(--fj-green-color));
	}
}

.mdl-progress:not(.mdl-progress--indeterminate) > .auxbar, .mdl-progress:not(.mdl-progress__indeterminate) > .auxbar {
	background-image: linear-gradient(to right, rgba(255, 255, 255, .9), rgba(255, 255, 255, .9)), linear-gradient(to right, var(--fj-green-color), var(--fj-green-color))
}

.mdl-progress.mdl-progress--indeterminate > .bar1, .mdl-progress.mdl-progress__indeterminate > .bar1 {
	background-color:          var(--fj-green-color);
}

.mdl-progress.mdl-progress--indeterminate > .bar3, .mdl-progress.mdl-progress__indeterminate > .bar3 {
	background-color:          var(--fj-green-color);
}
