.create-lab-user {
	width: 400px;
}

@media (max-width: 768px) {
	.page-wrapper {
		padding-top: 0;
	}

	.mini-sidebar.show-sidebar .page-wrapper {
		overflow: hidden;
	}
}