@import '../../themeCSS/variables.css';

.new-user-button {
    color: #ffffff;
    background: var(--fj-green-color);
    border-radius: 4px;
    padding: 8px 13px;
    font-size: 15px;
    white-space: nowrap;
    border-width: 0;
    line-height: 1.6;
}

.new-user-button .fa {
    font-size: 21px;
}

.new-user-button:hover, .new-user-button:visited, .new-user-button:focus {
    cursor: pointer;
}

.new-user-button:hover {
    background: var(--fj-green-hover-color);
}
