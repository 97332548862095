.marker-label {
	color:      #fff;
	text-align: center;
	box-sizing: border-box;
	overflow:   visible !important;
}

.marker-label > div, .marker-label-wrapper-1, .marker-label-wrapper-2 {
	height: 100%;
}

.marker-label-wrapper-2 {
	background-color: #ee4039;
	position:         relative;
	z-index:          1;
	overflow:         hidden;
	text-overflow:    ellipsis;
	border-radius:    4px;
	box-shadow:       0px 0px 4px 0px rgba(0, 0, 0, 0.5);
}

.marker-label-wrapper-2 > * {
	padding-left:  2px;
	padding-right: 2px;
}

.marker-label-wrapper-2 > *:first-child {
	padding-top: 2px;
}

.marker-label-wrapper-2 > *:last-child {
	padding-bottom: 2px;
}

.marker-label .triangle {
	width:        0;
	height:       0;
	border-left:  4px solid transparent;
	border-right: 4px solid transparent;
	border-top:   7px solid #ee4039;
	position:     absolute;
	bottom:       0;
	left:         50%;
	z-index:      1;
}

.marker-initials {
	background-color: #df7300;
}

.control-container {
	margin:  10px;
	z-index: 0;
	/*position: absolute;*/
	left:    0px;
	top:     0px;
	cursor:  pointer;
}

.control-button-container {
	float:    left;
	position: relative;
}

.control-button {
	direction:                  ltr;
	overflow:                   hidden;
	text-align:                 center;
	position:                   relative;
	color:                      rgb(86, 86, 86);
	font-family:                Roboto, Arial, sans-serif;
	user-select:                none;
	font-size:                  11px !important;
	background-color:           rgb(255, 255, 255);
	padding:                    11px;
	border-bottom-right-radius: 2px;
	border-top-right-radius:    2px;
	background-clip:            padding-box;
	box-shadow:                 rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
	border-left:                0px;
}

.control-button.active {
	color:       #000;
	font-weight: 500 !important;
}

.control-button:hover {
	color:            #000;
	background-color: rgb(235, 235, 235);
}

.current-location .pulse {
	-moz-animation:        pulsate 1.5s ease-in-out infinite;
	-webkit-animation:     pulsate 1.5s ease-in-out infinite;
	border:                1pt solid #fff;
	/* make a circle */
	-moz-border-radius:    51px;
	-webkit-border-radius: 51px;
	border-radius:         51px;
	/* multiply the shadows, inside and outside the circle */
	-moz-box-shadow:       inset 0 0 5px #06f, inset 0 0 5px #06f, inset 0 0 5px #06f, 0 0 5px #06f, 0 0 5px #06f, 0 0 5px #06f;
	-webkit-box-shadow:    inset 0 0 5px #06f, inset 0 0 5px #06f, inset 0 0 5px #06f, 0 0 5px #06f, 0 0 5px #06f, 0 0 5px #06f;
	box-shadow:            inset 0 0 5px #06f, inset 0 0 5px #06f, inset 0 0 5px #06f, 0 0 5px #06f, 0 0 5px #06f, 0 0 5px #06f;
	/* set the ring's new dimension and re-center it */
	height:                51px !important;
	/*margin:                -18px 0 0 -18px;*/
	width:                 51px !important;
	position:              absolute;
	top:                   8px;
	left:                  8px;
}

.current-location > div, .current-location > div > div {
	width:  100%;
	height: 100%;
}

.current-location img {
	position: absolute;
	top:      calc(50% - 17px / 2);
	left:     calc(50% - 17px / 2);
	width:    17px;
}

@-moz-keyframes pulsate {
	from {
		-moz-transform: scale(0.25);
		opacity:        1.0;
	}
	95% {
		-moz-transform: scale(1.3);
		opacity:        0;
	}
	to {
		-moz-transform: scale(0.3);
		opacity:        0;
	}
}

@-webkit-keyframes pulsate {
	from {
		-webkit-transform: scale(0.25);
		opacity:           1.0;
	}
	95% {
		-webkit-transform: scale(1.3);
		opacity:           0;
	}
	to {
		-webkit-transform: scale(0.3);
		opacity:           0;
	}
}

.inbox-dot {
	position:      absolute;
	top:           -3px;
	right:         -3px;
	width:         9px;
	height:        9px;
	background:    #ff6165;
	border-radius: 50%;
	z-index:       5;
	border:        1px solid rgba(0, 0, 0, .1);
}