#analysis-result-uploader {
	position: relative;
}

#analysis-result-uploader .overlay {
	position:         absolute;
	top:              0;
	left:             0;
	bottom:           0;
	right:            0;
	background-color: rgba(255, 255, 255, .7);
	z-index:          21;

	display:          flex;
	flex-direction:   column;
	justify-content:  center;
	align-items:      center;
}

#analysis-result-uploader.page-uploader .overlay {
	position: fixed;
}

#analysis-result-uploader .message {
	text-align:  center;
	font-size:   40px;
	font-weight: bold;
}

#analysis-result-uploader .failed {
	color: red;
}

#analysis-result-uploader-modal > .modal-dialog {
	min-width: 800px;
	max-width: 100%;
}

.file-list {
	display:         table;
	width:           100%;
	border-collapse: collapse;
}

.file-list > div {
	display: table-row;
}

.file-list > div > div {
	display: table-cell;
	border:  1px solid rgba(0, 0, 0, 0.02);
	padding: 7px 5px;
}

.file-list > div > div:nth-child(3) {
	text-align: center;
}

.file-list .failed {
	color: red;
}