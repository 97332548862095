@import './../../themeCSS/variables.css';

.no-analysis {
	text-align: center;
	padding:    10px;
}

.load-more-container {
	text-align: center;
	padding:    10px;
}

.load-more-container > button {
	min-width: 300px;
}

.ReactTable.analysis-table .rt-tbody .rt-tr {
	cursor: pointer;
}

.ReactTable.analysis-table .rt-tbody .rt-td {
	color: var(--fj-link-color);
}

.ReactTable.analysis-table .rt-tbody .rt-tr.overdue {
	background-color: #F1CCC7;
}

.ReactTable.analysis-table .rt-tbody .rt-tr.overdue.-odd {
	background-color: #EBB8B1;
}

.ReactTable.analysis-table .rt-tbody .rt-tr.due-today {
	background-color: #F4D9C7;
}

.ReactTable.analysis-table .rt-tbody .rt-tr.due-today.-odd {
	background-color: #E9B48E;
}
.ReactTable .rt-thead .rt-tr .rt-th, .ReactTable .rt-thead .rt-tr .rt-td {
	padding: 7px 5px;
	color: var(--fj-dark-text-color);
}
.ReactTable.analysis-table .rt-tbody .rt-tr.overdue:hover,
.ReactTable.analysis-table .rt-tbody .rt-tr.due-today:hover {
	background-color: var(--table-tr-hover-color);
}
/*
.ReactTable.analysis-table .rt-tbody .rt-tr.overdue .rt-td:nth-child(10) {
	background-color: #ffcccc;
}

.ReactTable.analysis-table .rt-tbody .rt-tr.overdue.-odd .rt-td:nth-child(10), .ReactTable.analysis-table .rt-tbody .rt-tr.overdue:hover .rt-td:nth-child(10) {
	background-color: #f2c2c2;
}*/

.rejection-reason {
	height: 5em;
}

.analysis-table .status {
	white-space: normal;
}

.analysis-table .status span {
	display: block;
}

.analysis-table .rt-th.-sort-desc, .analysis-table .rt-th.-sort-asc,
.ReactTable .rt-thead.-header .rt-th.-sort-desc:after, .ReactTable .rt-thead.-header .rt-th.-sort-asc:after {
	color: var(--fj-green-color) !important;
}

.analysis-table .days-left.due-today {
	/*color: #4d4d00;*/
}

.analyses .search-container {
	padding:          10px;
	max-width:        300px;
	background-color: #fafafa;
	margin-bottom:    1.25rem;
	display:          inline-block;
}

.analyses .search-inner-container {
	display:        flex;
	flex-direction: row;
}

.analyses .search-container i {
	margin-right: .3em;
}

.analyses .search-box, .analyses .search-box:focus {
	background-color: transparent;
	outline:          none;
	border:           none;
	padding:          0;
	box-shadow:       none;
	margin-top:       -10px;
	margin-bottom:    -10px;
}