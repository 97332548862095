@import '../../themeCSS/variables.css';

.main-nav-btn {
	margin: 6px 0;
	padding: 8px 13px;
	border-width: 0;
	border-radius: 4px;
	background-color: #ffffff;
	font-size: 15px;
	line-height: 1.6;
	margin-left: .2em;
}
.main-nav-btn i.fa {
	font-size: 21px;
	vertical-align: middle;
	width: 27px;
	display: inline-block;
}

.inbox-btn {
	color: var(--fj-link-color);
}
.inbox-btn.active {
	background: var(--fj-link-color);
	color: #FFFFFF;
}

.inbox-non-empty {
	color: var(--fj-red-color);
}
.inbox-non-empty.active {
	background: var(--fj-red-color);
	color: #FFFFFF;
}

.open-btn {
	color: var(--fj-red2-color);
}
.open-btn.active {
	background: var(--fj-red2-color);
	color: #FFFFFF;
}

.results-btn {
	color: var(--fj-green-color);
}
.results-btn.active {
	background: var(--fj-green-color);
	color: #FFFFFF;
}

.cancelled-btn {
	color: var(--fj-link-color);
}
.cancelled-btn.active {
	background: var(--fj-link-color);
	color: #FFFFFF;
}

.main-nav-btn:hover {
	color: var(--fj-dark-text-color);
	cursor: pointer;
}
.main-nav-btn.active:hover {
	color: #FFFFFF;
}
