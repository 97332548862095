:root {
    --fj-dark-text-color: #3D312E;
    --fj-link-color: #6D635D;
    --secondary-button-color: #817B77;
    --fj-light-text-color: #ABA7A4;
    --table-tr-hover-color: #D5D3D2;
    --blank-color: #F0EFEF;
    --fj-green-color: #7EB549;
    --fj-green-hover-color: #9ec776;
    --fj-red-color: #C53520;
    --fj-red2-color: #D3691E;
}
