.topbar {
	/*position: fixed;*/
	top:   0px;
	width: 100%;
}

.topbar .top-navbar {
	max-width: none;
}

.topbar .branding {
	height: 65px;
}

.topbar .logo {
	width:        218px;
	margin-right: 10px;
}

.topbar .logo-text {
	display:        inline-block;
	/* line-height: 0.6; */
	vertical-align: middle;
	font-size:      21px;
	margin:         0;
}

.topbar .nav-link {
	min-width:  55px;
	text-align: center;
}

.topbar .title {
	color: #fff;
}

.dropdown-user .email {
	display:       block;
	font-size:     11px;
	max-width:     150px;
	overflow:      hidden;
	text-overflow: ellipsis;
}

.mini-sidebar .top-navbar .navbar-header {
	width: auto;
}

@media (max-width: 768px) {
	.topbar .navbar-nav .nav-link span {
		display: none;
	}

	.topbar .logo-text {
		display: none;
	}
}