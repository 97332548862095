@import '../../themeCSS/variables.css';

.help-dropdown-item {
    display: block;
    width: 100%;
    padding: 7px 24px;
    clear: both;
    font-weight: 400;
    color: var(--fj-dark-text-color);
    white-space: nowrap;
    background-color: transparent;
    border: 0;
}
.help a, .help a:visited {
    color: var(--fj-link-color);
}

.help a:hover, .help a:focus {
    color: var(--fj-green-color);
}
